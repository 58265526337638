body {
  line-height: 0.8em;
}

.wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main.container {
  margin: 5em 0 2em 0;
  flex: 1;
}

span.nobr {
  white-space: nowrap;
}

.label {
  margin-left: 0;
}

.handPointer {
  cursor: pointer;
  cursor: hand;
}

.ui.buttons {
  display: flex;
}

.ui.steps {
  border-radius: 0;
  border-bottom: 0;
  border-top: 0;
}

.ui.steps .step {
  padding: 0.6em 2em;
}

.ui.steps .step > .icon {
  font-size: 1.5em;
}

.ui.menu:not(.vertical) .right {
  display: flex;
  margin-left: auto !important;
}

.ui.steps .step .description {
  white-space: nowrap;
  overflow: hidden;
}

.ui.table thead th {
  padding: 0.6em;
}

.image-gallery {
  max-width: 330px;
}

.ui.modal {
  top: 30%;
}

.ui.tiny.footer.image {
  padding: 12px 0;
}

.ui.small.footer.image {
  padding: 8px 0;
}

.ui.inverted.vertical.segment {
  background: #4e4e4e;
}

.ui.table:first-child {
  margin-top: 1em;
}
